import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "p-top-bottom-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")
  const _component_custom_table = _resolveComponent("custom-table")
  const _component_custom_form_template = _resolveComponent("custom-form-template")

  return (_ctx.listData.total>0)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$wordList.AutomaticPaymentRecord), 1),
        _createVNode(_component_custom_form_template, {
          "page-size": _ctx.formData.row,
          "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.row = $event)),
          "cur-page": _ctx.formData.page,
          "onUpdate:cur-page": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.page = $event)),
          total: _ctx.listData.total,
          onSizeChange: _ctx.getListForPmSubscriptionApi,
          onPageChange: _ctx.getListForPmSubscriptionApi
        }, {
          table: _withCtx(() => [
            _createVNode(_component_custom_table, {
              column: _ctx.column,
              tableData: _ctx.listData.row
            }, {
              IntervalType: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.billingCycleMap[scope.item['IntervalType']]), 1)
              ]),
              Status: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.autoPayStatusMap[scope.item['Status']]), 1)
              ]),
              TotalPrice: _withCtx((scope) => [
                _createTextVNode(" $" + _toDisplayString(scope.item['TotalPrice']), 1)
              ]),
              Cycles: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(scope.item['Cycles']===0?_ctx.$wordList.Unlimited:scope.item['Cycles']), 1)
              ]),
              op: _withCtx((scope) => [
                _createVNode(_component_svg_icon, {
                  name: "icon-info",
                  class: "m-right-md cursor-pointer",
                  color: "color-warning",
                  fontSize: "20px",
                  onClick: ($event: any) => (_ctx.toDetail(scope.item)),
                  title: _ctx.$wordList.PersonuserInfo
                }, null, 8, ["onClick", "title"])
              ]),
              _: 1
            }, 8, ["column", "tableData"])
          ]),
          _: 1
        }, 8, ["page-size", "cur-page", "total", "onSizeChange", "onPageChange"])
      ]))
    : _createCommentVNode("", true)
}