
import { reactive, defineComponent } from 'vue';
import { getListForPmSubscription, GetListForPmSubscription } from '@/api/office/subscription';
import { billingCycleMap, autoPayStatusMap } from '@/constant/field-map';

export default defineComponent({
    setup(props, { emit }) {
        const column = [
            { prop: 'IntervalType', label: WordList.RenewType },
            { prop: 'TimeZone', label: WordList.TabelCommunitiesTimeZone },
            { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime },
            { prop: 'Cycles', label: WordList.RenewTimes },
            { prop: 'NextPayTime', label: WordList.NextApproximatePaydate },
            { prop: 'TotalPrice', label: WordList.OrderTotalPrice },
            { prop: 'Status', label: WordList.MulListUpdateListTanleStatus },
            { prop: 'CreateTime', label: WordList.TabelMessageBoxCreateTime },
            { prop: 'op', label: WordList.CaptureListTanleAction }
        ];

        const formData = reactive({
            row: 10,
            page: 1
        });
        const listData = reactive<GetListForPmSubscription>({
            total: 0,
            row: []
        });
        getListForPmSubscriptionApi();
        function getListForPmSubscriptionApi() {
            getListForPmSubscription(formData, (res: GetListForPmSubscription) => {
                listData.row = res.row;
                listData.total = res.total;
            });
        }
        function toDetail(item: GetListForPmSubscription['row'][0]) {
            emit('changeStep', { type: 'forward', to: 'autoPayInfo', data: item });
        }
        return {
            column,
            formData,
            listData,
            getListForPmSubscriptionApi,
            toDetail,
            billingCycleMap,
            autoPayStatusMap
        };
    }
});

